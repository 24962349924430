




import Vue from 'vue';
import DeleteButton from '@/components/Button/CircleButton.vue';
import Notification from '@/model/notification';
import Qualification from '@/model/qualification';

export default Vue.extend({
  name: 'DeleteQualification',
  components: {
    DeleteButton,
  },
  props: {
    qualification: Object as () => Qualification,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    deleteQualification() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(
            this.$t('admin.basic-information-settings.qualification.delete.confirmation', {
              name: this.qualification.name,
            }).toString(),
          ),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.loading = true;
            this.$store.dispatch('qualificationStorage/delete', this.qualification.id).finally(() => {
              this.loading = false;
              this.$emit('deleteFinished');
            });
          }
        });
    },
  },
});
