
































import Vue from 'vue';
import Table from '@/components/Table/Table.vue';
import PageOptions from '@/model/page/page-options';
import Pageable from '@/model/page/pageable';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BasicInformationOptionsTable',
  components: {
    Table,
  },
  props: {
    title: { type: String, default: '' },
    data: { type: Object as () => Pageable<any> },
    loading: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters({ filtering: 'filterStorage/isFiltering' }),
  },
  data() {
    return {
      tableFields: [
        {
          text: this.$t('admin.basic-information-settings.name'),
          key: 'name',
          label: this.$t('admin.basic-information-settings.name'),
          sortable: true,
          thStyle: 'width: 75%',
        },
        {
          key: 'buttons',
          label: '',
          thStyle: 'width: 25%',
        },
      ],
    };
  },
  methods: {
    pageOptionsChanged(newPageOptions: PageOptions) {
      this.$emit('pageOptionsChanged', newPageOptions);
    },
  },
});
