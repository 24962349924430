















import Vue from 'vue';
import Protectors from '@/components/Admin/BasicInformationOptions/Protectors/Protectors.vue';
import Qualifications from '@/components/Admin/BasicInformationOptions/Qualifications/Qualifications.vue';
import Roles from '@/components/Admin/BasicInformationOptions/Roles/Roles.vue';
import Types from '@/components/Admin/BasicInformationOptions/Types/Types.vue';
import WorkFields from '@/components/Admin/BasicInformationOptions/WorkFields/WorkFields.vue';
import AdminPageButtons from '@/components/Admin/PageButtons.vue';
import { mapGetters } from 'vuex';
import PageOptions from '@/model/page/page-options';
import Menu from '@/model/menu';

export default Vue.extend({
  name: 'BasicInformationOptions',
  components: {
    Protectors,
    Qualifications,
    Roles,
    Types,
    WorkFields,
    AdminPageButtons,
  },
  props: {
    selectedPage: { type: Object as () => Menu },
  },
  watch: {
    selectedPage() {
      this.page = this.selectedPage;
    },
  },
  data() {
    return {
      page: this.selectedPage,
    };
  },
  computed: {
    ...mapGetters({ searchText: 'filterStorage/getAdminBasicInformationOptionsSearchText' }),
  },
  mounted() {
    this.$emit('setFilter', this.searchText);
  },
  methods: {
    textFiltering(text: string) {
      this.$store.commit('filterStorage/setAdminBasicInformationOptionsSearchText', text);
      this.$store.commit('filterStorage/setFiltering', true);
      this.$store.dispatch('toolCategoryStorage/getPageableToolCategories', { pageOptions: new PageOptions() });
      this.$store.dispatch('protectorStorage/getPageableProtectors', { pageOptions: new PageOptions() });
      this.$store.dispatch('qualificationStorage/getPageableQualifications', { pageOptions: new PageOptions() });
      this.$store.dispatch('personRoleStorage/getPageableRoles', { pageOptions: new PageOptions() });
      this.$store.dispatch('businessTypeStorage/getPageableBusinessTypes', { pageOptions: new PageOptions() });
      this.$store.dispatch('workFieldStorage/getPageableWorkFields', { pageOptions: new PageOptions() });
      this.$nextTick(() => this.$store.commit('filterStorage/setFiltering', false));
    },
  },
});
