var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasicInformationOptionsTable',{attrs:{"title":_vm.$t('admin.basic-information-settings.qualification.title'),"data":_vm.qualifications,"loading":_vm.loading},on:{"pageOptionsChanged":_vm.pageOptionsChanged},scopedSlots:_vm._u([{key:"editionButton",fn:function(ref){
var item = ref.item;
return [_c('BasicInformationOptionsForm',{ref:((item.id) + "-additionForm"),attrs:{"title":_vm.$t('admin.basic-information-settings.qualification.modify.title'),"originalName":item.name},on:{"save":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.save.apply(void 0, [ item.id ].concat( argsArray ))}}})]}},{key:"deleteButton",fn:function(ref){
var item = ref.item;
return [_c('DeleteQualification',{attrs:{"qualification":item}})]}},{key:"additionButton",fn:function(){return [_c('BasicInformationOptionsForm',{ref:"additionForm",attrs:{"title":_vm.$t('admin.basic-information-settings.qualification.add.title')},on:{"save":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.save.apply(void 0, [ null ].concat( argsArray ))}}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }