































import Vue from 'vue';
import FormBase from '@/components/Form/Form.vue';
import EditButton from '@/components/Button/CircleButton.vue';
import TextField from '@/components/InputField/TextField.vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BasicInformationOptionsForm',
  components: {
    FormBase,
    EditButton,
    TextField,
  },
  props: {
    title: { type: String, default: '' },
    originalName: { type: String, default: '' },
  },
  data() {
    return {
      plus: '/images/plus.svg',
      name: '',
      loading: false,
      submitted: false,
    };
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(100),
    },
  },
  computed: {
    ...mapGetters({ filtering: 'filterStorage/isFiltering' }),
  },
  methods: {
    openDialog() {
      if (this.originalName) {
        this.name = this.originalName;
      }
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
    },
    resetForm() {
      this.name = '';
      this.loading = false;
      this.submitted = false;
    },
    saveChange() {
      this.submitted = true;
      this.$v.name.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.loading = true;
      this.$emit('save', this.name);
    },
  },
});
