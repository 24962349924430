




























import Vue from 'vue';
import BasicInformationOptionsTable from '@/components/Admin/BasicInformationOptions/BasicInformationOptionsTable.vue';
import BasicInformationOptionsForm from '@/components/Admin/BasicInformationOptions/BasicInformationOptionsForm.vue';
import DeleteProtector from '@/components/Admin/BasicInformationOptions/Protectors/DeleteProtector.vue';
import PageOptions from '@/model/page/page-options';
import { mapGetters } from 'vuex';
import BasicInformationRequest from '@/model/request/basic-information-request';

export default Vue.extend({
  name: 'Protectors',
  components: {
    BasicInformationOptionsTable,
    BasicInformationOptionsForm,
    DeleteProtector,
  },
  data() {
    return {
      loading: false,
      pageOptions: new PageOptions(0, 25),
      tableFields: [
        {
          text: this.$t('admin.basic-information-settings.name'),
          key: 'name',
          label: this.$t('admin.basic-information-settings.name'),
          sortable: true,
          thStyle: 'width: 75%',
        },
        {
          key: 'buttons',
          label: '',
          thStyle: 'width: 25%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ protectors: 'protectorStorage/getPageableProtectors' }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store.dispatch('protectorStorage/getPageableProtectors', this.pageOptions).finally(() => {
        this.loading = false;
      });
    },
    save(id: number, name: string) {
      this.$store
        .dispatch(id ? 'protectorStorage/modify' : 'protectorStorage/add', new BasicInformationRequest(name, id))
        .finally(() => {
          const form = id ? (this.$refs[`${id}-additionForm`] as any) : (this.$refs.additionForm as any);
          form.closeDialog();
        });
    },
    pageOptionsChanged(newPageOptions: PageOptions): void {
      this.pageOptions = newPageOptions;
      this.fetch();
    },
  },
});
